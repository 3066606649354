import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Welcome to "Galactic Quest: Adventure Beyond the Stars"!
			</title>
			<meta name={"description"} content={"Embark on an interstellar journey filled with breathtaking galaxies, cosmic battles, and epic quests. \"Galactic Quest\" invites you to become a space explorer, uncover hidden secrets of the universe, and forge alliances with alien civilizations."} />
			<meta property={"og:title"} content={"Welcome to \"Galactic Quest: Adventure Beyond the Stars\"!"} />
			<meta property={"og:description"} content={"Embark on an interstellar journey filled with breathtaking galaxies, cosmic battles, and epic quests. \"Galactic Quest\" invites you to become a space explorer, uncover hidden secrets of the universe, and forge alliances with alien civilizations."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});